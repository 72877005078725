import ApiERP from '@/core/conectores/ApiERP';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IPessoa } from '@/models/Entidades/Cadastros/Pessoas/IPessoa';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IDTOPessoa } from '@/models/DTO/Cadastros/Pessoas/IDTOPessoa';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
/**
 * Serviço de Fornecedores
 * Fornece todas regras de negócios e lógicas relacionado aos fornecedores.
 */

class ServicoFornecedor implements IServicoBase<IPessoa> {
  endPoint = 'fornecedores';

  apiERP = new ApiERP();

  public requisicaoSistema():void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCpfCnpj?:boolean): Promise<IItemConsultaRapida[]> {
    let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    if (UtilitarioGeral.validaBoleano(apresentarCpfCnpj)) {
      parametrosAdicionais += `&apresentarCpfCnpj=${apresentarCpfCnpj}`;
    }
    const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOPessoa): string {
    return objeto.nomeCompletoRazaoSocial;
  }

  public async incluir(fornecedor: IPessoa): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, fornecedor);
    return result.data;
  }

  public async alterar(fornecedor: IPessoa): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${fornecedor.codigo}`, fornecedor);
    return result.data;
  }

  public async obter(codigo: number, empresa: number): Promise<IPessoa> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }
}

export default ServicoFornecedor;
