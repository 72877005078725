
import {
  computed, defineComponent, onMounted, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import DisplayGrupoCampos from './DisplayGrupoCampos.vue';
import { IGrupoCamposPersonalizado, IPersonalizacaoTela } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import { ETipoElementoGrupoCampos } from '@/models/Enumeradores/MeuSistema/PersonalizacoesTelas/ETipoElementoGrupoCampos';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'DisplayTelaPersonalizada',
  props: {
    codigoRegistro: {
      type: Number,
      required: true,
    },
    personalizacao: {
      type: Object as () => IPersonalizacaoTela,
      default: {} as IPersonalizacaoTela,
    },
  },
  components: {
    Card,
    DisplayGrupoCampos,
  },
  emits: ['update:personalizacao'],
  setup(props, { emit }) {
    const servicoPersonalizacaoTela = new ServicoPersonalizacaoTela();

    const state = reactive({
      carregando: false,
      abas: [] as IGrupoCamposPersonalizado[],
      paineis: [] as IGrupoCamposPersonalizado[],
    });

    const computedPersonalizacao = computed({
      get: () => props.personalizacao,
      set: (personalizacao: IPersonalizacaoTela) => {
        emit('update:personalizacao', personalizacao);
      },
    });

    onMounted(async () => {
      state.carregando = true;
      if (computedPersonalizacao.value.codigo !== undefined && computedPersonalizacao.value.codigo > 0) {
        computedPersonalizacao.value.gruposCamposPersonalizados = await servicoPersonalizacaoTela.obterGruposCampos(props.personalizacao.codigo, storeSistema.getters.codigoEmpresaOperacao());
        computedPersonalizacao.value.gruposCamposPersonalizados.forEach((g) => {
          if (g.tipoElemento === ETipoElementoGrupoCampos.Aba) {
            state.abas.push(g);
          } else {
            state.paineis.push(g);
          }
        });
      }
      state.carregando = false;
    });

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      retorno = await servicoPersonalizacaoTela.atualizarValoresCampos(props.codigoRegistro, storeSistema.getters.codigoEmpresaOperacao(), props.personalizacao.gruposCamposPersonalizados);
      return retorno;
    }

    return {
      props,
      state,
      salvar,
    };
  },
});
