
import {
  computed, defineComponent, onMounted, reactive,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarHora from '@/core/components/Tela/SelecionarHora.vue';
import SelecionarValorCampoPersonalizado from '@/core/components/Tela/SelecionarValorCampoPersonalizado.vue';
import { ICampoPersonalizado, IItemCampoPersonalizado, IValorCampoPersonalizado } from '@/models/Entidades/MeuSistema/PersonalizacoesTelas/IPersonalizacaoTela';
import { ETipoCampoPersonalizado } from '@/models/Enumeradores/MeuSistema/PersonalizacoesTelas/ETipoCampoPersonalizado';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarItemCampoPersonalizado from './SelecionarItemCampoPersonalizado.vue';

export default defineComponent({
  name: 'DisplayCampoPersonalizado',
  props: {
    codigoRegistro: {
      type: Number,
      required: true,
    },
    campo: {
      type: Object as () => ICampoPersonalizado,
      default: {} as ICampoPersonalizado,
    },
  },
  components: {
    Icone,
    CampoNumerico,
    SelecionarData,
    SelecionarHora,
    SelecionarValorCampoPersonalizado,
    SelecionarItemCampoPersonalizado,
  },
  emits: ['update:campo', 'update:valorBoolean', 'update:valores'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const servicoPersonalizacaoTelas = new ServicoPersonalizacaoTela();

    const state = reactive({
      valorNumerico: 0.0,
      valorBoolean: false,
      itensSelecionados: [] as string[],
      carregando: false,
    });

    const computedCampo = computed({
      get: () => props.campo,
      set: (campo: ICampoPersonalizado) => {
        emit('update:campo', campo);
      },
    });

    function iniciarComponente() {
      computedCampo.value.itens = [] as IItemCampoPersonalizado[];
      state.valorNumerico = 0.0;
      state.valorBoolean = false;
    }

    function atualizarValorNumerico() {
      computedCampo.value.valores[0].valor = UtilitarioGeral.obterApenasNumeros(String(state.valorNumerico));
    }

    function atualizarValorBooleano() {
      computedCampo.value.valores[0].valor = String(state.valorBoolean);
    }

    function atualizarValoresSelecionados() {
      computedCampo.value.valores = [] as IValorCampoPersonalizado[];
      state.itensSelecionados.forEach((i) => {
        const valor = {} as IValorCampoPersonalizado;
        valor.codigoCampoPersonalizado = props.campo.codigo;
        valor.registro = props.codigoRegistro;
        valor.valor = i;
        computedCampo.value.valores.push(valor);
      });
    }

    function obterTamanhoMaximo() {
      if (props.campo.tamanhoMaximo === 0) {
        return 10000;
      }

      return props.campo.tamanhoMaximo;
    }

    function preencherValor() {
      if (props.campo.tipoCampo === ETipoCampoPersonalizado.ListaSelecao) {
        props.campo.valores.forEach((valor) => {
          state.itensSelecionados.push(valor.valor);
        });
      } else if (props.campo.tipoCampo === ETipoCampoPersonalizado.Inteiro) {
        state.valorNumerico = Number(props.campo.valores[0].valor);
      } else if (props.campo.tipoCampo === ETipoCampoPersonalizado.Fracionado) {
        state.valorNumerico = Number.parseFloat(props.campo.valores[0].valor);
      } else if (props.campo.tipoCampo === ETipoCampoPersonalizado.Check || props.campo.tipoCampo === ETipoCampoPersonalizado.Switch) {
        state.valorBoolean = props.campo.valores[0].valor === 'true';
      }
    }

    onMounted(async () => {
      state.carregando = true;
      iniciarComponente();
      if (props.campo.tipoCampo === ETipoCampoPersonalizado.ComboBox || props.campo.tipoCampo === ETipoCampoPersonalizado.ListaSelecao) {
        const itens = await servicoPersonalizacaoTelas.obterItensCampo(props.campo.codigo, storeSistema.getters.codigoEmpresaOperacao());
        if (UtilitarioGeral.validaLista(itens)) {
          computedCampo.value.itens = itens;
        }
      }
      preencherValor();
      state.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      UtilitarioGeral,
      ETipoCampoPersonalizado,
      computedCampo,
      obterTamanhoMaximo,
      atualizarValorNumerico,
      atualizarValorBooleano,
      atualizarValoresSelecionados,
    };
  },
});
